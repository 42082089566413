import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { EHeaderTab } from '@/components/ui/SearchHeader/UniversalSearch/UniversalSearchContainer/menu';
import { EClientSearchTriggers, ESearchFilters } from '@/constants/searchFilters';
import { vehicleStyleToTypeMap } from '@/constants/vehicleDetails';
import { EVehicleType } from '@/redux/modules/globalHeader';
import { applySearchFilter } from '@/redux/modules/search';
import { getHeaderSearchFilters } from '@/redux/selectors/globalHeader';
import { getDeliveryCenter, getDeliveryDetails } from '@/redux/selectors/mapboxPlace';
import { parseFlexibleDateRange } from '@/redux/selectors/queryParams';
import { trackRenterSearchedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import { parseEventDate } from '@/services/analytics/utils';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { getSurfacedLocation } from '@/utility/surfacedLocation';

import { EHomeSearchWidgetVariant } from './HomeSearchWidget';

export const useApplySearch = (variant: EHomeSearchWidgetVariant) => {
  const dispatch = useDispatch();

  const filters = useSelector(getHeaderSearchFilters);
  const deliveryCenter = useSelector(getDeliveryCenter);
  const deliveryDetails = useSelector(getDeliveryDetails);

  return async () => {
    trackRenterSearchedEvent({
      surfacedLocations: getSurfacedLocation(),
      totalAdults: filters.guests?.adults || 0,
      totalChildren: filters.guests?.children || 0,
      totalInfants: null,
      locationQuery: filters.address || null,
      source:
        variant === EHomeSearchWidgetVariant.StationaryRv ? 'stationary_delivery' : 'home_search',
      isADAAccessible: !!filters.guests?.accessible,
      isPetFriendly: !!filters.guests?.pet_friendly,
      flexibleDateRange: parseFlexibleDateRange(
        filters.dates?.from?.toString(),
        filters.dates?.to?.toString(),
        filters.dates?.flexible_days?.toString(),
      ),
      departureDate: (filters.dates?.from && parseEventDate(filters.dates.from)) || null,
      returnDate: (filters.dates?.to && parseEventDate(filters.dates.to)) || null,
      hasCategories: true,
      selectedCategory: (filters.vehicleType as unknown as EHeaderTab) || EHeaderTab.ALL,
      ...(filters.vehicleType === EVehicleType.TOWABLE
        ? {
            towableYear: filters.vehicle?.year?.toString() || null,
            towableMake: filters.vehicle?.make?.toString() || null,
            towableModel: filters.vehicle?.model?.toString() || null,
          }
        : {
            towableYear: null,
            towableMake: null,
            towableModel: null,
          }),
      rentalType: ERentalType.RV,
      searchFilterCategory: null,
      lastQuestionSeen: null,
    });

    await dispatch(
      applySearchFilter(
        {
          [ESearchFilters.ADDRESS]: filters.address,
          [ESearchFilters.DATE_FROM]:
            filters.dates?.from && filters.dates?.to
              ? dayjs(filters.dates.from).format('YYYY-MM-DD')
              : undefined,
          [ESearchFilters.DATE_TO]:
            filters.dates?.from && filters.dates?.to
              ? dayjs(filters.dates.to).format('YYYY-MM-DD')
              : undefined,
          [ESearchFilters.FLEXIBLE_DAYS]:
            filters.dates?.from && filters.dates?.to
              ? filters.dates.flexible_days?.toString()
              : undefined,
          [ESearchFilters.GUESTS_ADULTS]: filters.guests?.adults
            ? String(filters.guests.adults)
            : undefined,
          [ESearchFilters.GUESTS_CHILDREN]: filters.guests?.children
            ? String(filters.guests.children)
            : undefined,
          [ESearchFilters.FILTER_FEATURE]: [
            ...(filters.guests?.pet_friendly ? ['pet_friendly'] : []),
            ...(filters.guests?.accessible ? ['accessible'] : []),
          ],
          ...(filters.vehicleType === EVehicleType.DRIVABLE
            ? {
                [ESearchFilters.FILTER_TYPE]: vehicleStyleToTypeMap.drivable.join(','),
              }
            : {}),
          ...(filters.vehicleType === EVehicleType.TOWABLE
            ? {
                [ESearchFilters.WEIGHT_LESSER]:
                  filters.vehicle?.max_towing_capacity?.toString() || undefined,
                [ESearchFilters.FILTER_TYPE]: vehicleStyleToTypeMap.towable.join(','),
                [ESearchFilters.SUGGESTED_TOWING_CAPACITY]:
                  filters.vehicle?.suggested_towing_capacity?.toString() || undefined,
                [ESearchFilters.TOWING_ID]: filters.vehicle?.id.toString() || undefined,
              }
            : {}),
          ...(filters.vehicleType === EVehicleType.DELIVERABLES
            ? {
                [ESearchFilters.DELIVERY]: 'true',
                [ESearchFilters.DELIVERY_ADDRESS]: filters.address,
                [ESearchFilters.DELIVERY_STATIONARY]: EDeliveryOption.STATIONARY,
                [ESearchFilters.DELIVERY_CENTER]: deliveryCenter,
                [ESearchFilters.DELIVERY_DETAILS]: deliveryDetails,
                ...(!filters.address
                  ? { [EClientSearchTriggers.OPEN_STATIONARY_DELIVERY_MODAL]: 'true' }
                  : {}),
              }
            : {}),
        },
        false,
        false,
        true,
      ),
    );
  };
};
