import {
  AmenitiesAdaAdaIcon,
  AmenitiesPetsPetFriendlyIcon,
  Divider,
  ETextStyleVariant,
  NumberInput,
  Text,
  Toggle,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { IHeaderSearchFilterGuests, setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import { getHeaderSearchGuestsFilter } from '@/redux/selectors/globalHeader';
import { trackEvent } from '@/services/track-event';

import { FilterContent, TFilterContentSharedProps } from './FilterContent';

type TGuestsFilterContentProps = TFilterContentSharedProps;

export const GuestsFilterContent = (props: TGuestsFilterContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const guests = useSelector(getHeaderSearchGuestsFilter);

  const changeGuestsSelection = (selection: IHeaderSearchFilterGuests) => {
    dispatch(
      setHeaderSearchFilter({
        guests: selection,
      }),
    );

    Object.entries(selection)
      .filter(([key, value]) => value !== guests?.[key as keyof IHeaderSearchFilterGuests])
      .forEach(([key, value]) => {
        trackEvent({
          event: ['adults', 'children', 'infants'].includes(key)
            ? `search/filter/guests/sleeps[${key}]`
            : `search/filter/guests/${key}`,
          action: 'update',
          before: guests?.[key as keyof IHeaderSearchFilterGuests],
          after: value,
        });
      });
  };

  return (
    <FilterContent
      header={
        <>
          {intl.formatMessage({
            defaultMessage: 'Add guest details',
            id: '7E+Zl5',
            description: 'Home Search Widget > Guests Modal Title',
          })}
        </>
      }
      content={
        <div className="lg:grid lg:grid-cols-6">
          <div className="lg:col-span-2">
            <Text variant={ETextStyleVariant.Label} className="mb-5 text-gray-500">
              {intl.formatMessage({
                defaultMessage: 'Guests',
                id: '1LkgyR',
                description: 'Home Search Widget > Guests Caption',
              })}
            </Text>

            <div className="flex flex-col lg:gap-4">
              <div className="flex items-center justify-between">
                <div>
                  <Text variant={ETextStyleVariant.MediumRegular}>
                    {intl.formatMessage({
                      defaultMessage: 'Adults',
                      id: '11vOH4',
                      description: 'Home Search Widget > Adult Guests Selector Label',
                    })}
                  </Text>

                  <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-500">
                    {intl.formatMessage({
                      description: 'Home Search Widget > Adult Guests Selector Disclaimer',
                      defaultMessage: 'Ages 13 and up',
                      id: '8KaYQ5',
                    })}
                  </Text>
                </div>

                <NumberInput
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Adults',
                    id: '11vOH4',
                    description: 'Home Search Widget > Adult Guests Selector Label',
                  })}
                  value={guests?.adults || 0}
                  onChange={value => changeGuestsSelection({ ...guests, adults: value })}
                  maxValue={10}
                />
              </div>

              <Divider className="my-4 lg:hidden" />

              <div className="flex items-center justify-between">
                <div>
                  <Text variant={ETextStyleVariant.MediumRegular}>
                    {intl.formatMessage({
                      defaultMessage: 'Children',
                      id: 'GdHzLz',
                      description: 'Home Search Widget > Child Guests Selector Label',
                    })}
                  </Text>

                  <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-500">
                    {intl.formatMessage({
                      description: 'Home Search Widget > Child Guests Selector Disclaimer',
                      defaultMessage: 'Ages 12 and under',
                      id: 'Lm8Q92',
                    })}
                  </Text>
                </div>

                <NumberInput
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Children',
                    id: 'GdHzLz',
                    description: 'Home Search Widget > Child Guests Selector Label',
                  })}
                  value={guests?.children || 0}
                  onChange={value => changeGuestsSelection({ ...guests, children: value })}
                  maxValue={10}
                />
              </div>

              <Divider className="my-4 lg:hidden" />
            </div>
          </div>

          <div className="lg:col-span-2 lg:col-start-4">
            <Text variant={ETextStyleVariant.Label} className="mb-5 text-gray-500">
              {intl.formatMessage({
                defaultMessage: 'Requirements',
                id: 'OHPsBi',
                description: 'Home Search Widget > Requirements Caption',
              })}
            </Text>

            <div className="flex flex-col lg:gap-5 gap-4">
              <div className="flex items-center justify-between">
                <Text variant={ETextStyleVariant.MediumRegular} className="flex items-center">
                  <AmenitiesPetsPetFriendlyIcon
                    className="inline-flex mr-2 text-gray-700"
                    width={18}
                    height={18}
                  />

                  {intl.formatMessage({
                    defaultMessage: 'Pet-friendly',
                    id: 'Hh+Y9E',
                    description: 'Home Search Widget > Pet-friendly Selector Label',
                  })}
                </Text>

                <Toggle
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Pet-friendly',
                    id: 'Hh+Y9E',
                    description: 'Home Search Widget > Pet-friendly Selector Label',
                  })}
                  isSelected={!!guests?.pet_friendly}
                  onChange={value => changeGuestsSelection({ ...guests, pet_friendly: value })}
                />
              </div>

              <div className="flex items-center justify-between">
                <Text variant={ETextStyleVariant.MediumRegular} className="flex items-center">
                  <AmenitiesAdaAdaIcon
                    className="inline-flex mr-2 text-gray-700"
                    width={18}
                    height={18}
                  />

                  {intl.formatMessage({
                    defaultMessage: 'ADA Accessible',
                    id: 'TYeibI',
                    description: 'Home Search Widget > ADA Accessible Selector Label',
                  })}
                </Text>

                <Toggle
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'ADA Accessible',
                    id: 'TYeibI',
                    description: 'Home Search Widget > ADA Accessible Selector Label',
                  })}
                  isSelected={!!guests?.accessible}
                  onChange={value => changeGuestsSelection({ ...guests, accessible: value })}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {...props}
    />
  );
};
