import {
  Divider,
  EIconButtonSize,
  EIconButtonVariant,
  ELinkColorVariant,
  ELinkVariant,
  ETextStyleVariant,
  GeneralCaretDownIcon,
  GeneralCaretUpIcon,
  IconButton,
  Link,
} from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '@/components/switchback/DatePicker/DatePicker';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IHeaderSearchFilterDates, setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import { getHeaderSearchDatesFilter } from '@/redux/selectors/globalHeader';
import { trackEvent } from '@/services/track-event';
import { formatDateRange } from '@/utility/format-date';

import { FilterContent, TFilterContentSharedProps } from './FilterContent';

type TDateFilterContentProps = TFilterContentSharedProps & {
  onDataSelection?: () => void;
};

export const DateFilterContent = ({ onDataSelection, ...props }: TDateFilterContentProps) => {
  const dispatch = useDispatch();

  const { isAboveDesktop } = useBreakpoint();

  const dates = useSelector(getHeaderSearchDatesFilter);

  const changeDatesSelection = (selection: IHeaderSearchFilterDates) => {
    dispatch(
      setHeaderSearchFilter({
        dates: selection,
      }),
    );

    trackEvent({
      event: 'search/filter/dates',
      action: 'update',
      before: {
        'date[from]': dates?.from ? dayjs(dates.from).format('YYYY-MM-DD') : undefined,
        'date[to]': dates?.to ? dayjs(dates.to).format('YYYY-MM-DD') : undefined,
      },
      after: {
        'date[from]': selection?.from ? dayjs(selection.from).format('YYYY-MM-DD') : undefined,
        'date[to]': selection?.to ? dayjs(selection.to).format('YYYY-MM-DD') : undefined,
      },
    });
  };

  return (
    <FilterContent
      header={
        <DateFilterContentHeader
          onClearDates={() => {
            changeDatesSelection({});
          }}
        />
      }
      content={
        <div className="flex flex-col h-full overflow-hidden">
          <div className="flex-grow overflow-auto">
            <FilterDatePicker
              desktop={isAboveDesktop}
              from={dates?.from}
              to={dates?.to}
              onChange={(from, to) => {
                if (to && from) {
                  changeDatesSelection({
                    ...dates,
                    from,
                    to,
                  });

                  onDataSelection?.();
                }
              }}
            />
          </div>

          <Divider className="mt-5 mb-3" />

          <FilterDatePickerFooter
            onFlexibleDaysChange={flexibleDays => {
              changeDatesSelection({
                ...dates,
                flexible_days: flexibleDays,
              });
            }}
            onClearDates={() => {
              changeDatesSelection({});
            }}
          />
        </div>
      }
      {...props}
    />
  );
};

type TDateFilterContentHeaderProps = {
  onClearDates: () => void;
};

const DateFilterContentHeader = ({ onClearDates }: TDateFilterContentHeaderProps) => {
  const intl = useIntl();

  const dates = useSelector(getHeaderSearchDatesFilter);

  if (!dates?.from || !dates?.to) {
    return (
      <>
        {intl.formatMessage({
          defaultMessage: 'When?',
          id: 'JQMCp9',
          description: 'Home Search Widget > Dates Modal Title',
        })}
      </>
    );
  }

  return (
    <div className="flex items-center gap-4">
      <span>{formatDateRange(dates.from.toString(), dates.to.toString(), false)}</span>

      <Link
        label={intl.formatMessage({ defaultMessage: 'Clear dates', id: 'zyDnZa' })}
        variant={ELinkVariant.Underline}
        colorVariant={ELinkColorVariant.Secondary}
        textVariant={ETextStyleVariant.SmallRegular}
        onClick={onClearDates}
      />
    </div>
  );
};

type TFilterDatePickerProps = {
  desktop?: boolean;
  from?: Date;
  to?: Date;
  onChange?: (from?: Date, to?: Date) => void;
};

const FilterDatePicker = ({
  desktop,
  from: initialFrom,
  to: initialTo,
  onChange,
}: TFilterDatePickerProps) => {
  const [selectedDates, setSelectedDates] = useState({
    from: initialFrom,
    to: initialTo,
  });

  // Keep in sync if we update from outside
  useEffect(() => {
    setSelectedDates({
      from: initialFrom,
      to: initialTo,
    });
  }, [initialFrom, initialTo]);

  const [initialMonth, setInitialMonth] = useState(initialFrom);

  const toBeDisabled = useMemo(
    () => new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()),
    [],
  );

  const mobileCanGoToPrevMonth = useMemo(
    () => !dayjs().isSame(initialMonth, 'month'),
    [initialMonth],
  );

  const mobileCanGoToNextMonth = useMemo(
    () => !dayjs().add(22, 'month').isSame(initialMonth, 'month'),
    [initialMonth],
  );

  return (
    <div>
      {!desktop && mobileCanGoToPrevMonth && (
        <div className="flex justify-center">
          <IconButton
            icon={GeneralCaretUpIcon}
            variant={EIconButtonVariant.Ghost}
            size={EIconButtonSize.Medium}
            onClick={() => {
              setInitialMonth(dayjs(initialMonth).subtract(1, 'month').startOf('month').toDate());
            }}
          />
        </div>
      )}

      <DatePicker
        range
        numberOfMonths={desktop ? 2 : 3}
        isStacked={!desktop}
        onChange={selection => {
          setSelectedDates(selection);
          onChange?.(selection?.from, selection?.to);
        }}
        disableBefore={toBeDisabled}
        dateTo={selectedDates.to}
        dateFrom={selectedDates.from}
        toMonth={dayjs().add(2, 'year').toDate()}
        month={initialMonth}
        onMonthChange={setInitialMonth}
      />

      {!desktop && mobileCanGoToNextMonth && (
        <div className="flex justify-center">
          <IconButton
            icon={GeneralCaretDownIcon}
            variant={EIconButtonVariant.Ghost}
            size={EIconButtonSize.Medium}
            onClick={() => {
              setInitialMonth(dayjs(initialMonth).add(1, 'month').startOf('month').toDate());
            }}
          />
        </div>
      )}
    </div>
  );
};

type TFilterDatePickerFooterProps = {
  onFlexibleDaysChange: (flexibleDays?: number) => void;
  onClearDates: () => void;
};

const FilterDatePickerFooter = ({
  onFlexibleDaysChange,
  onClearDates,
}: TFilterDatePickerFooterProps) => {
  const intl = useIntl();

  const dates = useSelector(getHeaderSearchDatesFilter);

  const selectedLinkProps = {
    variant: ELinkVariant.Underline,
    colorVariant: ELinkColorVariant.Primary,
    className: '!underline',
  };

  return (
    <div className="flex items-center justify-between gap-10">
      <div className="flex gap-4">
        <Link
          label={intl.formatMessage({ defaultMessage: 'Match exact dates', id: 'uhcpp1' })}
          variant={ELinkVariant.NoUnderline}
          colorVariant={ELinkColorVariant.Secondary}
          {...(!dates?.flexible_days && selectedLinkProps)}
          textVariant={ETextStyleVariant.SmallRegular}
          onClick={() => onFlexibleDaysChange(undefined)}
        />

        <Link
          label={intl.formatMessage({ defaultMessage: '±1 day', id: 'Y9uTsx' })}
          variant={ELinkVariant.NoUnderline}
          colorVariant={ELinkColorVariant.Secondary}
          {...(dates?.flexible_days === 1 && selectedLinkProps)}
          textVariant={ETextStyleVariant.SmallRegular}
          onClick={() => onFlexibleDaysChange(1)}
        />

        <Link
          label={intl.formatMessage({ defaultMessage: '±3 day', id: 'shUTHK' })}
          variant={ELinkVariant.NoUnderline}
          colorVariant={ELinkColorVariant.Secondary}
          {...(dates?.flexible_days === 3 && selectedLinkProps)}
          textVariant={ETextStyleVariant.SmallRegular}
          onClick={() => onFlexibleDaysChange(3)}
        />
      </div>

      <div className="hidden lg:block">
        <Link
          label={intl.formatMessage({ defaultMessage: 'Clear dates', id: 'zyDnZa' })}
          variant={ELinkVariant.Underline}
          colorVariant={ELinkColorVariant.Primary}
          textVariant={ETextStyleVariant.SmallRegular}
          onClick={onClearDates}
        />
      </div>
    </div>
  );
};
